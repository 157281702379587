import React from "react"

export default () => 
<div className="position">
    <h6>Digital Project Manager</h6>
    <p>ICON Worldwide is a software development company based in Zurich and Sofia. For over twenty years we have partnered with Fortune 500 companies across all business sectors including finance, pharma, universities, and start-ups.</p>
    <p>For our Sofia office we are seeking an experienced Project Manager.</p>
    <p>The ideal candidate has experience managing multiple, fast-moving projects for corporate clients. You will lead a team of expert developers to build top-notch software with a strong focus on quality and usability. You will undertake technical challenges, make technical decisions and support the team, such that everyone executes to the best of their abilities. You will also manage client expectations and tasks lists.</p>
    <p>This is an excellent opportunity for highly motivated, energetic, individuals who enjoy working in an international environment.</p>

    <h6>Requirements include:</h6>               
    <ul>
    <li>- Excellent spoken and written English</li>
    <li>- Experience managing developer teams, working across platforms including - Drupal, Magnolia, WordPress, iOS, Android, etc.</li>
    <li>- Thorough knowledge of the project management lifecycle.</li>
    <li>- Must be knowledgeable of modern project management frameworks: Agile, Waterfall, Kanban</li>
    <li>- Prior experience of working as an Account Manager/Project Manager within a digital agency</li>
    <li>- Experience leading projects with CMS implementation efforts</li>
    <li>- Experience with Google Analytics is a plus</li>
    <li>- Understanding of search engine optimization and search marketing best practices</li>
    <li>- Experience conducting usability testing</li>
    <li>- Hands-on experience with information architecture</li>
    </ul>

    <h6>Responsibilities:</h6>               
    <ul>
    <li>- Manage a team of 10 engineers to ensure high productivity & quality level</li>
    <li>- Take ownership of technical processes and improve them</li>
    <li>- Planning & Effort estimation to ensure predictable product delivery</li>
    <li>- Prove effort estimates and feasibility for planned features</li>
    <li>- Take technical decisions in all phases of product design and development</li>
    <li>- Lead daily Scrum meetings</li>
    <li>- Manage enterprise-level web development projects from inception to launch</li>
    <li>- Develop and manage end-to-end project plans and ensure on-time delivery</li>
    <li>- Drive the overall strategy for the client’s website and marketing efforts</li>
    <li>- Ability to manage the fi nancial aspects of projects</li>
    <li>- Lead client meetings around positioning, digital needs, project status, and integrated digital marketing plans.</li>
    <li>- Attain a very high client satisfaction rating throughout the engagement</li>
    </ul>
    
    <h6>Personal Attributes:</h6>
    <ul>
    <li>- Creativity and strong attention to detail</li>
    <li>- Strong client-service orientation and customer service skills</li>
    <li>- Proven ability to manage projects to successful completion, multi-task, and work within tight deadlines</li>
    <li>- Strong business and marketing acumen and negotiation skills</li>
    <li>- Positive, productive team player</li>
    <li>- Communication and collaboration skills: mentoring, coaching, sharing and shaping ideas, identifying opportunities, teamwork</li>
    <li>- Self-motivation and a strong desire to learn, grow and seek out new challenges</li>
    <li>- Relevant educational and project experience, particularly as a member of a team, is a major plus</li>
    </ul>

    <h6>We really value our employees and therefore we offer a substantial remuneration package for allroles, which includes:</h6>
    <ul>
        <li>- Highly competitive salary</li>
        <li>- Opportunities for career progression</li>
        <li>- Open communication with the management team</li>
        <li>- Flexible and remote working possibilities</li>
        <li>- Technology equipment</li>
        <li>- Team-building events (subject to COVID-19 restrictions)</li>
    </ul>
    <p>For the opportunity to become a key member of our highly motivated team and share in ourcontinued success - please submit your CV and a link to your portfolio.</p>
    <div id="apply-job">
    <a href="mailto:jobs@icon-worldwide.com?subject=Digital%20Project%20Manager%20-%20Job%20Application">Apply Now</a>
    </div>
</div>