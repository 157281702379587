import React from "react"

export default () => 
<div className="position">
    <h6>Mid-level Java Developer Full-Time or Flex (Remote)</h6>
    <p>ICON Worldwide is Software Development company with offi ces in Sofi a and Zurich.</p>
    <p>We are looking for a Mid-Senior level Java Developer to join our Sofi a team.</p>
    <p>We are an English-speaking office. You can work remote in Sofia coming to the office when necessary for client meetings, or as desired. We offer flexible working conditions (Full-time with benefits or Flexible/On-Demand), highly competitive salary (above Bulgarian standard), 20 days vacation.</p>

    <h6>The candidate we seek should have the following skills:</h6>               
    <ul>
    <li>- At least 2 years of programming experience with Java</li>
    <li>- Practical experience with Spring, Hibernate, Struts, SQL, and Tomcat as well as web services (REST)</li>
    <li>- Knowledge of HTML5, CSS3, JavaScript</li>
    <li>- Knowledge of Magnolia CMS is a plus</li>
    </ul>

    <h6>Nice to have skills:</h6>               
    <ul>
    <li>- Undergraduate or Post-Graduate Degree in Computer Science or a related field</li>
    <li>- Experience with consumer-facing analytics solutions (Google, Omniture, other) and techniques used to track user behavior and sales conversion.</li>
    <li>- Knowledge of HTML5, CSS3, JavaScript</li>
    <li>- Knowledge of Magnolia CMS is a plus</li>
    </ul>
    
    <h6>The ideal candidate will also have the following qualities:</h6>
    <ul>
    <li>- Communication and collaboration: mentoring, coaching, sharing and shaping ideas, identifying opportunities, teamwork</li>
    <li>- Self-motivation and a strong desire to learn, grow, and seek out new challenges</li>
    <li>- Relevant educational and project experience, particularly as a member of a team, is a major plus</li>
    </ul>
    <p>Become a key member of our highly motivated team and share in our continued success.</p>
    <p>If you are interested in learning more, please contact us directly.</p>
    <div id="apply-job">
    <a href="mailto:jobs@icon-worldwide.com?subject=Mid-level%20Java%20Developer%20-%20Job%20Application">Apply Now</a>
    </div>
</div>