import React from "react"
import SEO from "../components/seo"
import logo from "../../assets/images/logo-light.acdb767d.svg"
import arrowJobs from "../../assets/images/arrow.png"
import { Link } from "gatsby"

import ServiceMenu from "../components/services/servicesMenuHeader"
import Footer from "../components/footer"

import Java from "../components/careers/java"
import PM from "../components/careers/pm"


class Jobs extends React.Component {
    openJob = () => {
      let page = document.querySelector("#jobs");
      let jobButtons = page.querySelectorAll('[data-tab]');
      let bindAll = function() {
          for(let i = 0; i < jobButtons.length ; i++) {
              jobButtons[i].addEventListener('click', change, false);
          }
      };
  
      let clear = function() {
          for(let i = 0; i < jobButtons.length ; i++) {
              jobButtons[i].classList.remove('position-open');
              let id = jobButtons[i].getAttribute('data-tab');
              document.getElementById(id).classList.remove('position-open');
          }
      };
  
      let change = function(e) {
          clear();
          e.target.classList.add('position-open');
          let id = e.currentTarget.getAttribute('data-tab');
          document.getElementById(id).classList.add('position-open');
      };
  
      bindAll();

    }


  render() {
    return (
      <div id="jobs">
        <SEO title={'ICON Worldwide | Careers'} 
        description="Ready to join an innovative global, design and technology company? We have immediate openings in Zurich, NY and Sofia.  Visit the ICON Careers page now!"
         canonical={'https://icon-worldwide.com/careers'}/>
            <ServiceMenu/>
        <div className="leftHalf">
          <div className="jobsContainer">
              <div id="title">
                <h1>
                  We are <span>hiring</span>!
                </h1>
                <div className="line-title"></div>
              </div>
              <div className="link-container">
                <div>
                  <h2 data-tab="first-position" className="link position-open" onClick={this.openJob}>Mid-level Java Developer Full-Time or Flex (Remote)</h2>
                </div>
                <div>
                  <h2 data-tab="second-position" className="link" onClick={this.openJob}>Digital Project Manager</h2>
                </div>
            </div>
          </div>
        </div>

        <div className="rightHalf">
          <div className="jobsContainer">
          <div className="jobDescription position-open" id="first-position">
                    <Java/>
                </div>
                <div className="jobDescription" id="second-position">
                    <PM/>
                </div>
            </div>
        </div>
        <Footer/>
      </div>
    )
  }
}
export default Jobs
